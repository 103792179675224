import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import _ from 'lodash';

import { Button, Col, Row } from 'reactstrap';

//services
import * as guestApi from '../api/guestApi';

//notifications
import * as notify from '../utils/notify';

//loading
import LoadingLayer from '../components/loaders/LoadingLayer';

import { useMediaQuery } from 'react-responsive';
import { MAX_WIDTH_MOBILE } from '../utils/helpers';

//images
//import LogoMediaset from '../css/images/logo-mediaset.svg';

//download
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const GuestPage = () => {
	const isMobile = useMediaQuery({ maxWidth: MAX_WIDTH_MOBILE });
	let match = useRouteMatch('/guest/:eventid');
	const eventid = match ? match.params.eventid : null;

	const [guestData, setGuestData] = useState({});
	const [observaciones, setObservaciones] = useState('');
	const [confirmYes, setConfirmYes] = useState(0);
	const [confirmAction, setConfirmAction] = useState(0);
	const [isActive, setIsActive] = useState(false);

	const [isLoading, setIsLoading] = useState(true);
	const [pageLoading, setPageLoading] = useState(false);

	const history = useHistory();

	useEffect(() => {
		setConfirmAction(0);
		getEvent(eventid);
	}, [eventid]);

	const getEvent = async (eventid) => {
		setIsLoading(true);
		const data = await guestApi.getEvent(eventid);
		setGuestData(data.data);
		setIsLoading(false);
	};

	const reloadPage = (eventid) => {
		getEvent(eventid);
	};

	const ConfirmAttendance = (activa) => {
		setConfirmAction(1);
		if (activa == true) {
			setConfirmYes(1);
		}
		setIsActive(true);
	};

	const NoConfirmAttendance = (activa) => {
		setConfirmAction(1);
		if (activa == false) {
			setConfirmYes(0);
		}
		setIsActive(false);
	};

	const handleSubmitForm = async (e) => {
		e.preventDefault();
		setPageLoading(true);
		if (confirmAction == 1) {
			const confirmValues = {
				guid: eventid,
				observaciones: observaciones,
				confirmadaAsistencia: true,
				tipoConfirmacion: confirmYes == 0 ? 3 : 1,
			};

			const data = await guestApi.setEventConfirm(confirmValues);
			if (data && data.succeeded) {
				setConfirmAction(0);
				e.target.reset();
				reloadPage(data.data);
			} else {
				notify.error(
					`Ha ocurrido un error, no se ha podido confirmar su asistencia`
				);
			}
		} else {
			notify.error(`Confirme o decline la invitación al evento`);
		}
		setPageLoading(false);
	};

	const handleDownloadEntradas = async (entradasData) => {
		setPageLoading(true);

		if (entradasData && entradasData.zipName && entradasData.entradas) {
			if (entradasData.entradas.length > 1) {
				var zip = new JSZip();
				const remotePDFs = entradasData.entradas.map(async (entrada) => {
					const responsePDF = await fetch(entrada.fileUrl);
					const dataPDF = await responsePDF.blob();
					zip.file(entrada.fileName, dataPDF);
					return dataPDF;
				});

				Promise.all(remotePDFs).then(() => {
					zip.generateAsync({ type: 'blob' }).then((content) => {
						saveAs(content, entradasData.zipName);
					});
					setPageLoading(false);
				});
			} else {
				const response = await fetch(entradasData.entradas[0].fileUrl);
				const dataBlob = await response.blob();
				saveAs(dataBlob, entradasData.entradas[0].fileName);
				setPageLoading(false);
			}
		} else {
			notify.error(`Ha ocurrido un error generando las entradas.`);
			setPageLoading(false);
		}
	};


	return (
		<>
			{isLoading ? (
				<LoadingLayer visible={isLoading} />
			) : guestData ? (
				<form onSubmit={handleSubmitForm.bind(this)}>
					{pageLoading && <LoadingLayer visible={pageLoading} />}
					<Row className={isMobile ? 'Landing-mobile' : 'Landing'}>
						{isMobile && (
							<div className="logo">
								{/* <img src={LogoMediaset} alt="mediaset españa" /> */}
								<div className="texto-formulario">
									Formulario de confirmación de asistencia
								</div>
							</div>
						)}
						<Col lg={isMobile ? '2' : '3'} className="Rectngulo-37-izq">
							<div
								className="poster-cuadrado"
								style={{ backgroundImage: `url(${guestData.urlImagen})` }}
							></div>
							<div className="content-info">
								<div>
									<span className="event-title">{guestData.nombreEvento}</span>
								</div>
								<div className="number-invitations">
									<span>
										Invitaci&oacute;n para {guestData.numInvitadosString}{' '}
									</span>
								</div>
								<div className="Grupo-102">
									<div className="iconcalendar">
										<span className="fticon-calendar"></span>
									</div>
									<div className="event-date">
										<span>{guestData.fechaEvento}</span>
										<span className="event-time">
											<p>
												{guestData.esPhotocall &&
												guestData.horaPhotoCall != null
													? guestData.horaPhotoCall
													: guestData.horaEvento}
												h
											</p>
										</span>
									</div>
								</div>
								<div className="line-izq"></div>
								<div className="Grupo-103">
									<div className="iconlocation">
										<span className="fticon-location"></span>
									</div>
									<div>
										<span className="event-site">
											<>{guestData.nombreEventoLugar}</>
										</span>
										<span className="event-address">
											<p>{guestData.direccionEventoLugar}</p>
										</span>
									</div>
								</div>
							</div>
						</Col>
						{guestData.confirmadaAsistencia ? (
							<>
								<Col lg="4">
									<div className="Rectngulo-confirm-dch">
										{!isMobile && (
											<div>
												<span className="header-text">
													Formulario de confirmación de asistencia
												</span>
												<div className="line-dch"></div>
											</div>
										)}
										<div
											className={isMobile ? 'guest-name-mobile' : 'guest-name'}
										>
											<span>{guestData.nombreInvitado},</span>
										</div>
										<div className="invitation-register">
											<span>{guestData.textoInvitacion}</span>
										</div>
										<div className="Rectngulo-confirm col">
											<div
												className={
													guestData.tipoConfirmacion != 3
														? 'check-confirm'
														: 'check-noconfirm'
												}
											>
												<span
													className={
														guestData.tipoConfirmacion != 3
															? 'fticon-confirm'
															: 'fticon-state-cancel'
													}
												></span>
											</div>
											<div className="Confirmo-asistencia">
												<span>
													{guestData.tipoConfirmacion != 3
														? 'Asistencia confirmada'
														: 'Declino la invitación'}
												</span>
											</div>
										</div>
										{!guestData.esAnunciante && guestData.observaciones && (
											<div className="observations-confirm">
												<div className="Unin-2">
													<span className="fticon-message"></span>
												</div>
												<div className="observations-text-confirm">
													<span> {guestData.observaciones}</span>
												</div>
											</div>
										)}

										{guestData.confirmadaAsistencia && guestData.descargaEntradas && (
											<div className="download-area">
												<Button
													type="button"
													onClick={() => handleDownloadEntradas(guestData.descargaEntradas)}
													className="btn-dark"
												>
													Descargar entradas
												</Button>
											</div>
										)}
									</div>
									{guestData.tipoConfirmacion == 1 ? (
										<div className="Rectngulo-info-entradas">
											<div className="icon-entrada">
												<span className="fticon-ticket"></span>
											</div>
											<div className="text-info-entradas">
												<span>{guestData.textoInfoEntrada}</span>
												{guestData.correoTexto &&
													guestData.correoTexto != '' && (
														<span className="text-info-entradas-bold">
															[{guestData.correoInvitado}]
														</span>
													)}
											</div>
										</div>
									) : (
										guestData.tipoConfirmacion == 2 && (
											<div className="Rectngulo-info-protocol-accept-fueraDePlazo">
												<div className="icon-info-protocol-accept-fueraDePlazo">
													<span className="fticon-clock"></span>
												</div>
												<div className="text-protocol">
													<span> {guestData.textoFueraDePlazo}</span>
													{guestData.correoTexto &&
														guestData.correoTexto != '' && (
															<span className="text-info-entradas-bold">
																[{guestData.correoInvitado}]
															</span>
														)}
												</div>
											</div>
										)
									)}

									{guestData.textoInformacion &&
										guestData.textoInformacion != '' && (
											<div className="Rectngulo-info-protocol">
												<div className="icon-info-protocol">
													<span className="fticon-info"></span>
												</div>
												<div className="text-protocol">
													<span>{guestData.textoInformacion}</span>
												</div>
											</div>
										)}
								</Col>
							</>
						) : (
							<>
								<Col className="Rectngulo-37-dch col-md-auto">
									{!isMobile && (
										<div>
											<div className="header">
												<span className="header-text">
													Formulario de confirmación de asistencia
												</span>
											</div>
											<div className="line-dch" />
										</div>
									)}
									<div
										className={isMobile ? 'guest-name-mobile' : 'guest-name'}
									>
										<span>{guestData.nombreInvitado},</span>
									</div>
									<div
										className={
											isMobile ? 'invitacion-text-mobile' : 'invitation-text'
										}
									>
										<span>{guestData.textoInvitacion}</span>
									</div>
									<div
										className={
											isMobile
												? 'titulo-asistencia-mobile'
												: 'titulo-asistencia'
										}
									>
										<span>¿Asistirás al evento?</span>
									</div>
									{!isMobile ? (
										<div className="container-confirm">
											<div
												className={
													confirmAction == 1 && isActive
														? 'Rectngulo-confirm-yes-on'
														: 'Rectngulo-confirm-yes-off'
												}
												onClick={(e) => ConfirmAttendance(true)}
											>
												<div className="Trazado-confirm-yes-off">
													<i className="fticon-confirm"></i>
												</div>
												<div className="text-confirm-yes">
													<span>Sí. Confirmo mi asistencia</span>
												</div>
											</div>

											<div
												className={
													confirmAction == 1 && !isActive
														? 'Rectngulo-confirm-no-on'
														: 'Rectngulo-confirm-no-off'
												}
												onClick={(e) => NoConfirmAttendance(false)}
											>
												<div className="Trazado-confirm-no-off">
													<i className="fticon-state-cancel"></i>
												</div>
												<div className="text-confirm-no">
													<span>No. Declino la invitación</span>
												</div>
											</div>
										</div>
									) : (
										<div className="container-confirm-mobile">
											<div
												className={
													confirmAction == 1 && isActive
														? 'Rectngulo-confirm-yes-on-mobile'
														: 'Rectngulo-confirm-yes-off-mobile'
												}
												onClick={(e) => ConfirmAttendance(true)}
											>
												<div className="Trazado-mobile">
													<span className="fticon-confirm"></span>
												</div>
												<div className="text-mobile">
													<span>Sí. Confirmo mi asistencia</span>
												</div>
											</div>

											<div
												className={
													confirmAction == 1 && !isActive
														? 'Rectngulo-confirm-no-on-mobile'
														: 'Rectngulo-confirm-no-off-mobile'
												}
												onClick={(e) => NoConfirmAttendance(false)}
											>
												<div className="Trazado-mobile">
													<span className="fticon-state-cancel"></span>
												</div>
												<div className="text-mobile">
													<span>No. Declino la invitación</span>
												</div>
											</div>
										</div>
									)}
									{!guestData.esAnunciante && (
										<div>
											<div
												className={
													isMobile ? 'Observaciones-mobile' : 'observations'
												}
											>
												<span>Observaciones</span>
											</div>
											<div>
												<textarea
													label="observaciones"
													placeholder="Escribe tu comentario"
													name="observaciones"
													className={
														isMobile
															? 'Rectngulo-observations-mobile'
															: 'Rectngulo-observations'
													}
													rows="4"
													onChange={(event) =>
														setObservaciones(event.target.value)
													}
													value={observaciones}
												/>
											</div>
										</div>
									)}
									<div>
										<Button
											type="submit"
											className={
												isMobile ? 'btn-response-mobile' : 'btn-response'
											}
											disabled={!confirmAction}
										>
											Enviar respuesta
										</Button>
									</div>
								</Col>
							</>
						)}
					</Row>
				</form>
			) : (
				history.push(`/restringido`)
			)}
		</>
	);
};

export default GuestPage;
