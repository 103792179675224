import React, { useState, useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';
import _ from 'lodash';

import { Button, Col, Row } from 'reactstrap';

//forms
import { Formik } from 'formik';

//services
import * as guestApi from '../api/guestApi';

//componentes
import WhiteBox from '../components/common/elems/WhiteBox';
import NavBar from '../components/NavBar';
import LoadingLayer from '../components/loaders/LoadingLayer';

//notificaciones
import * as notify from '../utils/notify';

const EmailRGPD = () => {
	const formikRef = useRef();
	const [guestData, setGuestData] = useState({});
	let match = useRouteMatch('/RGPD/:eventid');

	const eventid = match ? match.params.eventid : null;
	const [isActive, setIsActive] = useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const [pageLoading, setPageLoading] = useState(false);

	useEffect(() => {
		getEvent(eventid);
	}, [eventid]);

	const getEvent = async (eventid) => {
		const data = await guestApi.getEventRGPD(eventid);
		setGuestData(data.data);
		setIsLoading(false);
	};

	const InitialActivo = {
		activo: guestData
			? guestData.contactoIdRGPD != null ||
			  guestData.contactoIdAnuncianteRGPD != null
				? true
				: false
			: false,
	};

	const darBaja = async (values, eventid) => {
		if (
			guestData.contactoIdRGPD != null ||
			guestData.contactoIdAnuncianteRGPD != null
		) {
			notify.error(`El usuario ya estaba dado de baja anteriormente.`);
		} else {
			setPageLoading(true);
			const data = await guestApi.setDarBaja(values, eventid);
			if (data && data.succeeded) {
				setIsActive(true);
			} else {
				notify.error(`Ha ocurrido un error dando de baja al usuario.`);
			}
			setPageLoading(false);
		}
	};
	return isLoading ? (
		<LoadingLayer visible={isLoading} />
	) : (
		<>
			{pageLoading && <LoadingLayer visible={pageLoading} />}
			<NavBar />
			<Formik
				innerRef={formikRef}
				enableReinitialize
				initialValues={InitialActivo}
				validateOnChange={false}
				validateOnBlur={false}
				onSubmit={darBaja}
			>
				{({ values, isSubmitting }) => {
					return (
						<div className="Rectngulo-112">
							<Row>
								<Col>
									<WhiteBox>
										<Row>
											{guestData &&
											guestData.contactoIdRGPD == null &&
											guestData.contactoIdAnuncianteRGPD == null ? (
												!isActive ? (
													<Col>
														<div className="icons-baja">
															<i className="fticon-mail" />
														</div>
														<div className="texto-baja">
															¿Quieres darte de baja?
														</div>
														<div className="texto-baja-info">
															<span>
																Si desactivas tu usuario dejarás de recibir
																invitaciones para futuros eventos y se
																desactivarán los mails asociados.
															</span>
														</div>
														<div>
															{/* <Button
																type="button"
																className="btn-prim-light btn-baja"
															>
																Cancelar
															</Button> */}
															<Button
																type="submit"
																onClick={() => darBaja(values.activo, eventid)}
																className="btn-prim-dark btn-baja"
																disabled={isSubmitting}
															>
																Dar de baja
															</Button>
														</div>
													</Col>
												) : (
													<Col>
														<div className="check-baja mt-2">
															<i className="fticon-confirm" />
														</div>
														<div className="mt-4 mb-4">
															<span className="texto-baja-info">
																Tu usuario ha sido dado de baja correctamente.
															</span>
														</div>
													</Col>
												)
											) : (
												<Col>
													<div className="icons-baja">
														<i className="fticon-search"></i>
													</div>
													<div className="mt-3">
														<span className="texto-baja">
															Usuario no encontrado
														</span>
													</div>
													<div className="mt-3 mb-3">
														<span className="texto-baja-info">
															Lo sentimos, no hemos podido encontrar el usuario
															solicitado.
														</span>
													</div>
												</Col>
											)}
										</Row>
									</WhiteBox>
								</Col>
							</Row>
						</div>
					);
				}}
			</Formik>
		</>
	);
};

export default EmailRGPD;
