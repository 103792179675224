import React from 'react';
import {
	Navbar,
	NavbarBrand,
} from 'reactstrap';

const NavBar = () => {
	return (
		<div>
			<Navbar color="main-bar" light expand="md">
				<NavbarBrand to="/"  className="logo">
					<div className="logo-full"></div>
				</NavbarBrand>				
			</Navbar>
		</div>
	);
};

export default NavBar;
