import { callApiGet, callApiPost } from './apiUtils';
const url_base = '/guest';

export const getEvent = async (eventID) => {
	return await callApiGet(`${url_base}/getEvent/${eventID}`);
};

export const setEventConfirm = async (dataConfirm) => {
	return await callApiPost(`${url_base}/setEventConfirm`, dataConfirm);
};

export const getPastEvents = async (id) => {
	return await callApiPost(`${url_base}/getOldEvents`, id);
};

export const setDarBaja = async (activo, eventID) => {
	return await callApiPost(`${url_base}/setDarBaja/${eventID}`, activo);
};

export const setDarBajaContacto = async (activo, eventID) => {
	return await callApiPost(`${url_base}/setDarBajaContacto/${eventID}`, activo);
};

export const getEventRGPD = async (eventID) => {
	return await callApiGet(`${url_base}/getEventRGPD/${eventID}`);
};

export const getContactoRGPD = async (eventID) => {
	return await callApiGet(`${url_base}/getContactoRGPD/${eventID}`);
};
