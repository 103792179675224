import React, { useContext, useState, useEffect } from 'react';

//Enrutado
import { Route } from 'react-router-dom';

//Notificaciones
import { ToastContainer } from 'react-toastify';

//Loader
import LoadingLayer from './loaders/LoadingLayer';

//Componentes
import { Container, Row, Col } from 'reactstrap';

const Layout = ({ component: Component, ...props }) => {
	return (
		<>
			<ToastContainer
				position="top-right"
				autoClose={4000}
				hideProgressBar
				newestOnTop={false}
				closeButton={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<Container fluid>
				<Row>
					<Col lg="12" className="navBarRGPD">
						<Route render={(properties) => <Component {...props} />} />
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Layout;
