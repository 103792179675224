import React, { Component } from 'react';

//Global CSS
import './css/app.scss';
import Layout from './components/Layout';

//Enrutado
import { BrowserRouter as Router, Switch } from 'react-router-dom';

//Genericos
import AccessDeniedPage from './pages/error/AccessDeniedPage';
import NotFoundPage from './pages/error/NotFoundPage';

import Guest from './pages/GuestPage';
import RGPD from './pages/EmailRGPD';
import NotificacionRGPD from './pages/NotificacionRGPD';

export default class App extends Component {
	static displayName = App.name;

	render() {
		return (
			<Router>
				<Switch>
					<Layout path="/guest/:eventid" component={Guest} />
					<Layout path="/RGPD/:eventid" component={RGPD} />
					<Layout path="/baja-notificacion/:eventid" component={NotificacionRGPD} />
					<Layout path="/restringido" component={AccessDeniedPage} />
					<Layout component={NotFoundPage} />
				</Switch>
			</Router>
		);
	}
}
