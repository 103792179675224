import { toast } from 'react-toastify';

const notification = (text, icon) => {
   toast(text, {
      icon: () =>  icon && <i className={icon}></i>
    });
}

export const info = (message) => {
   notification(message, null);
};

export const success = (message) => {
   notification(message, "fticon-confirm");
};

export const error = (message) => {
   notification(message, "fticon-state-cancel");
};