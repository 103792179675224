import React from 'react'

//loader
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingLayer = ({visible}) => {
    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={visible} >
				<CircularProgress color="inherit" />
			</Backdrop>
        </>
    )
}

export default LoadingLayer