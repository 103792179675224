import React from 'react';
import { Card, CardBody } from 'reactstrap';

const WhiteBox = ({ containerClass = '', ...props }) => {
	return (
		<Card className={containerClass} onClick={props.onClick && props.onClick}>
			<CardBody>{props.children}</CardBody>
		</Card>
	);
};

export default WhiteBox;
